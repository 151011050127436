import * as React from "react";
import PatientLayout from "../components/patient/layout";
import { Seo } from "../components/seo";


import "./index.scss";

const IndexPage = () => {
  return (
    <PatientLayout pageClass="patient-homepage" isPatientHero>

    </PatientLayout>
  );
};

export default IndexPage;

export const Head = () => <Seo title="Home" description="Find information about Hysingla ER Extended-Release Tablets CII. See Full PI, Medication Guide & Boxed Warning." />;
